(function($) {
  jQuery(window).on( 'load', function() {

      var $wrapper = document.getElementById('project-svg-container');

      if ( $wrapper ) {
        var $svgElement = $wrapper.getElementsByTagName('svg')[0],
          $viewBox = $svgElement.getAttribute('viewBox'),
          $widthHeight = $viewBox.split(/\s+|,/),
          $padding = 100 * $widthHeight[3] / $widthHeight[2];

        $wrapper.style.paddingBottom = $padding + '%';
      }


    var $tr = $('.offer-table .data-table-cell'),
      $svg = $('#project-svg-container svg').find('g.wohnungen g');

      $('#project-svg-container svg').click(function() {
      });

    $tr.on('touchstart mouseenter', function() {
      var offerID = $(this).parent().attr('data-appartment-id');
      $('#' + offerID).attr('class', 'hover');
    });

    $tr.on('touchend mouseout', function() {
      var offerID = $(this).parent().attr('data-appartment-id');
      $('#' + offerID).attr('class', '');
    });

    $svg.on('touchstart mouseenter', function() {
      var offerID = $(this).attr('id');
      $('[data-appartment-id="'+offerID+'"]').addClass('hover');
    });

    $svg.on('touchend mouseleave', function() {
      var offerID = $(this).attr('id');
      $('[data-appartment-id="'+offerID+'"]').removeClass('hover');
    });

    $svg.on( 'click', function(e) {
      e.preventDefault();
      var offerID = $(this).attr('id');
      window.location.href = $('[data-appartment-id="'+offerID+'"]').find('.icon-download').attr( 'href' );
    });
  }); // end ready
})( jQuery );
