// smooth scrolling  by https://css-tricks.com/snippets/jquery/smooth-scrolling/
jQuery(document).ready(function($) {
  $('a[href*="#"]:not([href="#"])').click(function() {
    if ($(this).hasClass('tab-nav')) { return; }
    if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });
});
