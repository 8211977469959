$(document).ready(function () {
  // form submit
  $('#inquiry').validate({
    rules :{
      request : {
        required : true
      },
      vorname : {
        required : true
      },
      nachname : {
        required : true
      },
      mail : {
        required : true,
        email: true
      },
      notes : {
        required : true
      }
    },
    messages :{
      request : {
        required : '<p class="hint">Bitte wählen Sie eine Option</p>'
      },
      vorname : {
        required : '<p class="hint">Bitte geben Sie Ihren Vornamen an</p>'
      },
      nachname : {
        required : '<p class="hint">Bitte geben Sie Ihren Namen an</p>'
      },
      mail : {
        required : '<p class="hint">Wir benötigen eine Mailadresse um Ihnen zu antworten</p>',
        email: '<p class="hint">Bitte geben Sie eine gültige E-Mail Adresse an</p>'
      },
      notes : {
        required : '<p class="hint">Bitte nennen Sie uns Ihr Anliegen</p>'
      }
    },
    submitHandler: function (form) {
      $.ajax({
        type: 'POST',
        url: $(form).attr('action'),
        data: $(form).serialize(),
        success: function () {
          $(form).addClass('hidden');
          $('.success').addClass('visible');
          }
        });
      return false; // required to block normal submit since you used ajax
    }
  });


});
